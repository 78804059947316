<template>
  <div id="thank-you-page">
    <div class="container" v-if="thankYou.content !== ''" v-html="thankYou.content"></div>
  </div>
</template>

<script>
export default {
  name: 'ThankYou',

  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME+' | תתחדשי... הזמנתך נקלטה בהצלחה | '+process.env.VUE_APP_NAME_HEB
    }
  },

  computed: {
    thankYou() {
      return this.$store.getters.thankYou
    },
    orderId() {
      return this.$store.getters.orderId
    },
    orderForFacebookMethod() {
      return this.$store.getters.orderForFacebookMethod
    },
  },

  async created() {
    this.$store.commit('clearCart')
    await this.$store.dispatch('getThankYou')

    if(this.$route.query.orderId) {
      this.$store.commit('setSaveCheckoutStatus', {
        status: false,
        orderId: this.$route.query.orderId,
        payplusIframe: '',
        paypal: ''
      })

      await this.$store.dispatch('getOrderForFacebookMethod', this.orderId)

      if(this.orderForFacebookMethod.status) {
        dataLayer.push(this.orderForFacebookMethod.method);
      }

      this.$router.push({ name: this.$route.name })
    }
  },

  destroyed() {
    //delete window.order
    this.$store.commit('setSaveCheckoutStatus', {
      status: false,
      orderId: null,
      payplusIframe: '',
      paypal: ''
    })
    this.$store.commit('setOrderForFacebookMethod', {})
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

#thank-you-page .container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  text-align: center;
  direction: rtl;
}
h1{
  font-size: 40px;
}
p{
  font-size: 25px;
}

@include media-breakpoint-down(sm) {
  #thank-you-page .container {
    min-height: 400px;
  }
  #thank-you-page h1 {
    font-size: 27px !important;
  }
  #thank-you-page p{
    font-size: 18px !important;
  }
}
</style>
